import React, { useState } from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";
import { postForm } from "../../helpers/SendFormHelper";

const RefinanceFormPage = () => {
  const [state, setState] = useState({
    isValidated: false,
    name: "",
    phone: "",
    email: "",
    goal: "Lower Payment",
    homeValue: 0,
    currentLoanAmount: 0,
    mortgagePaymentMonthly: 0,
    mortgageIncludesTaxInsurance: "Unsure",
    monthlyMortgageEscrowAmount: 0,
    monthlyMortgageInsuranceAmount: 0,
    loanTerm: "30 Years",
    loanYears: 0,
    interestRate: 0,
    vaLoanEligible: "No",
  });

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    postForm(form.getAttribute("name"), state)
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  return (
    <Layout>
      <section className="section" style={{ marginTop: "7rem" }}>
        <div className="container">
          <h2 className="title is-2 has-text-centered">Refinance Your Home</h2>
        </div>
        <section className="section">
          <div className="container" style={{ maxWidth: 900 }}>
            <form
              name="refinance-request"
              method="post"
              action="/contact/thanks/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="refinance-request" />
              <div hidden>
                <label>
                  Don’t fill this out:{" "}
                  <input name="bot-field" onChange={handleChange} />
                </label>
              </div>

              <div className="field">
                <label className="label" htmlFor={"name"}>
                  Your name
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={"text"}
                    name={"name"}
                    onChange={handleChange}
                    id={"name"}
                    required={true}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor={"phone"}>
                  Phone
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={"tel"}
                    name={"phone"}
                    onChange={handleChange}
                    id={"phone"}
                    required={true}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor={"email"}>
                  Email
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={"email"}
                    name={"email"}
                    onChange={handleChange}
                    id={"email"}
                    required={true}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor={"goal"}>
                  What is your refinance goal?
                </label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      className="input"
                      name={"goal"}
                      onChange={handleChange}
                      id={"goal"}
                    >
                      <option>Lower Payment</option>
                      <option>Shorter term (30 to 15 years)</option>
                      <option>Cash Out</option>
                      <option>Debt Consolidation</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor={"homeValue"}>
                  What is your home worth?
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="number"
                    name={"homeValue"}
                    onChange={handleChange}
                    id={"homeValue"}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor={"currentLoanAmount"}>
                  How much do you currently owe on the home?
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="number"
                    name={"currentLoanAmount"}
                    onChange={handleChange}
                    id={"currentLoanAmount"}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor={"mortgagePaymentMonthly"}>
                  What is your current monthly mortgage payment?
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="number"
                    name={"mortgagePaymentMonthly"}
                    onChange={handleChange}
                    id={"mortgagePaymentMonthly"}
                  />
                </div>
              </div>

              <div className="field">
                <label
                  className="label"
                  htmlFor={"mortgageIncludesTaxInsurance"}
                >
                  Does your monthly mortgage payment include property taxes,
                  hazard insurance and mortgage insurance?
                </label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      className="input"
                      name={"mortgageIncludesTaxInsurance"}
                      onChange={handleChange}
                      id={"mortgageIncludesTaxInsurance"}
                    >
                      <option>Unsure</option>
                      <option>No</option>
                      <option>Yes</option>
                    </select>
                  </div>
                </div>
              </div>

              <fieldset disabled={state.mortgageIncludesTaxInsurance !== "Yes"}>
                <div className="field">
                  <label
                    className="label"
                    htmlFor={"monthlyMortgageEscrowAmount"}
                  >
                    How much of your monthly mortgage payment is escrow?
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type="number"
                      name={"monthlyMortgageEscrowAmount"}
                      onChange={handleChange}
                      id={"monthlyMortgageEscrowAmount"}
                    />
                  </div>
                </div>

                <div className="field">
                  <label
                    className="label"
                    htmlFor={"monthlyMortgageInsuranceAmount"}
                  >
                    How much of your monthly mortgage payment is insurance?
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type="number"
                      name={"monthlyMortgageInsuranceAmount"}
                      onChange={handleChange}
                      id={"monthlyMortgageInsuranceAmount"}
                    />
                  </div>
                </div>
              </fieldset>

              <div className="field">
                <label className="label" htmlFor={"loanTerm"}>
                  What is your current loan term?
                </label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      className="input"
                      name={"loanTerm"}
                      onChange={handleChange}
                      id={"loanTerm"}
                    >
                      <option>30 Years</option>
                      <option>20 Years</option>
                      <option>15 Years</option>
                      <option>Other</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor={"loanYears"}>
                  How many years have you had your current loan?
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="number"
                    name={"loanYears"}
                    onChange={handleChange}
                    id={"loanYears"}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor={"interestRate"}>
                  What is your current interest rate?
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="number"
                    name={"interestRate"}
                    onChange={handleChange}
                    id={"interestRate"}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor={"vaLoanEligible"}>
                  Are you eligible for a VA loan?
                </label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      className="input"
                      name={"vaLoanEligible"}
                      onChange={handleChange}
                      id={"vaLoanEligible"}
                    >
                      <option>No</option>
                      <option>Yes</option>
                      <option>Unsure</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <button className="button is-link" type="submit">
                  Send
                </button>
              </div>
            </form>
          </div>
        </section>
      </section>
    </Layout>
  );
};

export default RefinanceFormPage;
